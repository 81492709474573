<template>
  <div>
    <div class="search">
      <div class="searche_item">
        <el-input v-model="search_val" placeholder="请输入内容"></el-input>
      </div>
      <div class="searche_item">
        <el-button type="primary" @click="search_data">查询</el-button>
      </div>
      <div class="searche_item">
        <el-button type="text" @click="add_dialog()"> 添加 </el-button>
      </div>
      <div class="searche_item">
        <el-upload
          :action="actionUrl"
          :data="actionData"
          :on-success="uploadSuccess"
          :show-file-list="false"
          :limit="1"
        >
          <el-button type="text">导入</el-button>
        </el-upload>
      </div>
      <div class="searche_item">
        <el-button type="text" @click="export_data()"> 导出 </el-button>
      </div>
    </div>
    <el-table border :data="tableList" style="width: 100%" :height="cheight">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="所属单位">
        <template slot-scope="{ row }">
          <span>{{ row.uAdminID_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属部门">
        <template slot-scope="{ row }">
          <span>{{ row.uDeptID_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="昵称" width="150px">
        <template slot-scope="{ row }">
          <span>{{ row.uNick }}</span>
        </template>
      </el-table-column>
      <el-table-column label="头像">
        <template slot-scope="{ row }">
          <img :src="row.uImg" style="width: 50px; height: 50px" alt="" />
        </template>
      </el-table-column>

      <el-table-column label="姓名">
        <template slot-scope="{ row }">
          <span>{{ row.uName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号码">
        <template slot-scope="{ row }">
          <span>{{ row.uTel }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属区域">
        <template slot-scope="{ row }">
          <span>{{ row.uCityID_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="150px">
        <template slot-scope="{ row }">
          <span>{{ row.uChecked_name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="注册时间">
        <template slot-scope="{ row }">
          <span>{{ row.uAddTime }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="{ row, $index }">
          <el-button type="text" size="small" @click="edit_click(row)">
            修改
          </el-button>
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="deleteRow(row, $index)"
          >
            <el-button type="text" size="small" slot="reference">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="m10">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="recordCount"
        :page-size="query.limit"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <Users
      :row_type="sel_row"
      :show="show_type"
      @close="close_type"
      @update="getList"
    />
  </div>
</template>

<script>
import Users from "./UsersAdd.vue";
import UploadSign from "@/utils/upload-sign";
export default {
  components: { Users },
  data() {
    return {
      actionUrl: process.env.VUE_APP_BASE_URL + "upload.php",
      actionData: Object.assign({ op: "files_upload" }, UploadSign),
      sel_row: {},
      show_type: false,
      search_val: "",
      recordCount: 0,
      query: {
        page: 1,
        limit: 25,
      },
      tableList: [],
      cheight: window.innerHeight - 280,
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      this.$api.import_users({ path: response.org }).then((res) => {
        // console.log(res);
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
      });
    },
    export_data() {
      // console.log(UploadSign);
      this.$api.export_users(this.query).then((res) => {
        // 处理返回的文件流
        const content = res.data;
        const blob = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var date =
          new Date().getFullYear() +
          "" +
          (new Date().getMonth() + 1) +
          "" +
          new Date().getDate() +
          "" +
          new Date().getHours() +
          "" +
          new Date().getMinutes() +
          "" +
          new Date().getSeconds() +
          "" +
          new Date().getMilliseconds();
        const fileName = date + "." + "xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        // console.log(res);
      });
    },
    search_data() {
      this.query.keyName = this.search_val;
      this.getList();
    },
    close_type() {
      this.show_type = false;
    },
    getList() {
      this.$api.users_list(this.query).then((res) => {
        this.tableList = res.data.rows;
        this.recordCount = res.data.total;
        // console.log(res.data);
      });
    },
    pageChange(currPage) {
      this.query.page = currPage;
      this.getList();
    },
    add_dialog() {
      this.show_type = true;
    },
    deleteRow(row) {
      console.log("deleteRow");
      this.$api.users_delete({ id: row.id }).then((res) => {
        this.getList();
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
      });
    },
    edit_click(row) {
      this.sel_row = row;
      this.show_type = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .searche_item {
    padding-right: 10px;
  }
}
</style>
